<template>
  <b-card>
    <b-button variant="primary" v-b-modal.discountModal>
      <feather-icon icon="PlusIcon"></feather-icon>
    </b-button>
    <b-button :variant="usableDiscount == true ? 'success' : 'danger'" @click="usableDiscountF" class="ml-1">
      <feather-icon :icon="usableDiscount== true ? 'CheckIcon' : 'XIcon'"></feather-icon>
      {{ usableDiscount == true ? $t("Discounts are usable") : $t("All dDiscounts") }}
    </b-button>
    <b-table ref="sessionTable" class="position-relative mt-3" :items="discounts" responsive :fields="tableColumns"
      show-empty :empty-text="$t('No Records Found')">
      <!-- <template #cell(title)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.title }}</span>
        </div>
      </template> -->
      <!-- <template #cell(ip)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ IPPARSER(data.item.ip) }}</span>
        </div>
      </template> -->
      <template #cell(rate)="data">
        <!-- <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
        </b-button> -->
        <span>
          {{ data.item.type == "percent" ? data.item.rate * 100 : data.item.rate }}
        </span>
      </template>
      <template #cell(type)="data">
        <!-- <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
        </b-button> -->
        <span>
          {{ data.item.type == "percent" ? "%" : "TL" }}
        </span>
      </template>
      <template #cell(usageLimit)="data">
        <!-- <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
        </b-button> -->
        <span>
          {{ data.item.usageLimit == -1 ? "\u221E" : data.item.usageLimit }}
        </span>
      </template>
      <template #cell(usableByAll)="data">
        <!-- <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
        </b-button> -->
        <feather-icon :icon="data.item.usableByAll ? 'CheckCircleIcon' : 'XCircleIcon'" size="18" />
      </template>
      <template #cell(usableUntil)="data">
        <!-- <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
        </b-button> -->
        <b-badge :variant="
            new Date(data.item.usableUntil && data.item.usableUntil) < new Date()
              ? 'danger'
              : 'success'
          ">
          {{ data.item.usableUntil
                ? new Date(data.item.usableUntil).toLocaleDateString()
                : "\u221E"
            }}
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <!-- <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
        </b-button> -->
        <b-button variant="outline-info" @click="editDiscount(data.item._id)">
          <feather-icon icon="EditIcon" size="18" />
        </b-button>
        <b-button variant="outline-danger" class="ml-1" @click="deleteDiscount(data.item._id)">
          <feather-icon icon="XIcon" size="18" />
        </b-button>
      </template>
      <div slot="empty" class="text-center">
        <span class="align-middle">{{ $t("No Records Found") }}</span>
      </div>
    </b-table>
    <b-modal id="discountModal" ref="discountModal" centered hide-footer :title="$t('Discount Coupon')"
      @hide="resetModal">
      <!-- code: "",
          rate: 1,
          type: "",
          usageLimit: -1,
          usableByAll: true,
          usableBy: [],
          usableForAll: true,
          usableFor: [],
          usableUntil: null, -->
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Code") }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input v-model="sendData.code" :placeholder="$t('Code')" @blur="checkUniqueCode" />
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Discount") }}</label>
        </b-col>
        <b-col sm="9">
          <b-input-group>
            <b-form-input v-model="sendData.rate" :placeholder="$t('Discount')" />
            <b-input-group-append class="d-flex flex-column ml-1">
              <b-form-radio v-model="sendData.type" value="percent"> % </b-form-radio>
              <b-form-radio v-model="sendData.type" value="amount"> TL </b-form-radio>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Usage Limit") }}</label>
        </b-col>
        <b-col sm="9">
          <b-input-group>
            <b-form-input v-model="sendData.usageLimit" :placeholder="$t('Usage Limit')" :style="{
                display: sendData.usageLimit == -1 ? 'none' : 'block',
              }" />
            <b-input-group-append style="display: flex; align-items: center; margin-left: 5px">
              <b-form-checkbox v-model="sendData.usageLimit" value="-1"
                unchecked-value="0">{{ "\u221E" }}</b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Usable By") }}</label>
        </b-col>
        <b-col sm="9">
          <b-input-group style="display: flex; justify-content: space-between">
            <v-select v-model="sendData.usableBy" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple
              :close-on-select="false" :options="users" label="users" style="width: 80%" input-id="add-users" :style="{
                display: sendData.usableByAll ? 'none' : 'block',
              }" :reduce="(users) => users._id">
              <template #option="{ name }">
                <!-- <b-avatar size="sm" :src="avatar" /> -->
                <span class="ml-50 align-middle"> {{ name }}</span>
              </template>
              <template #selected-option="{ name }">
                <!-- <b-avatar size="sm" class="border border-white" :src="avatar" /> -->
                <span class="ml-50 align-middle"> {{ name }}</span>
              </template>
            </v-select>
            <b-input-group-append style="display: flex; align-items: center; margin-left: 5px">
              <b-form-checkbox v-model="sendData.usableByAll" :value="true" :unchecked-value="false"
                @change="sendData.usableBy = []">{{ $t("All") }}</b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Usable For") }}</label>
        </b-col>
        <b-col sm="9">
          <b-input-group style="display: flex; justify-content: space-between">
            <v-select v-model="sendData.usableFor" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple
              :close-on-select="false" :options="packages" label="packages" style="width: 80%" input-id="add-package"
              :style="{
                display: sendData.usableForAll ? 'none' : 'block',
              }" :reduce="(packages) => packages._id">
              <template #option="{ title }">
                <!-- <b-avatar size="sm" :src="avatar" /> -->
                <span class="ml-50 align-middle"> {{ title }}</span>
              </template>
              <template #selected-option="{ title }">
                <!-- <b-avatar size="sm" class="border border-white" :src="avatar" /> -->
                <span class="ml-50 align-middle"> {{ title }}</span>
              </template>
            </v-select>
            <b-input-group-append style="display: flex; align-items: center; margin-left: 5px">
              <b-form-checkbox v-model="sendData.usableForAll" :value="true" :unchecked-value="false"
                @change="sendData.usableFor = []">{{ $t("All") }}</b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <label>{{ $t("Expiry Date") }}</label>
        </b-col>
        <b-col sm="9">
          <flat-pickr v-model="sendData.usableUntil" :config="{
              dateFormat: 'Y-m-d',
              locale: Locale.tr,
              allowInput: true,
            }" class="form-control" />
        </b-col>
      </b-row>
      <b-button variant="outline-success" class="mt-1 float-right" @click="saveButtonClicked">
        <feather-icon icon="SaveIcon"></feather-icon>
      </b-button>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BBadge,
  BForm,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BTable,
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
  BFormRadioGroup,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
const {
  axiosRoutes: { discount: discountPath, user: userPath, productPackage: packagePath },
} = require("/G_CONFIG");
import flatPickr from "vue-flatpickr-component";
import Locale from "flatpickr/dist/l10n/tr.js";

export default {
  components: {
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BTable,
    BModal,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      Locale,
      tableColumns: [
        { key: "code", label: this.$t("Code"), sortable: true },
        { key: "rate", label: this.$t("Amount"), sortable: true },
        { key: "type", label: this.$t("Type"), sortable: false },
        { key: "usageLimit", label: this.$t("Usage Limit"), sortable: true },
        { key: "usageCount", label: this.$t("Use Count"), sortable: true },
        { key: "usableByAll", label: this.$t("Usable By All"), sortable: true },
        { key: "usableUntil", label: this.$t("Expiry Date"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ],
      discounts: [
        {
          // actions: "Actions",
        },
      ],
      packages: [],
      users: [],
      sendData: {
        code: "",
        rate: 1,
        usageLimit: -1,
        type: "percent",
        usableByAll: true,
        usableBy: [],
        usableForAll: true,
        usableFor: [],
        usableUntil: null,
        _id: null,
        
      },
      usableDiscount: false,
    };
  },
  created() {
    this.getDiscounts();
    axios.get(userPath.getAll).then((res) => {
      this.users = res.data;
      // console.log("object :>> ", this.sessionList);
    });
    axios.get(packagePath.getAll).then((res) => {
      this.packages = res.data;
      // console.log("object :>> ", this.sessionList);
    });
    // axios.get(sessionPath.get).then((res) => {
    //   this.sessionList = res.data;
    //   // console.log("object :>> ", this.sessionList);
    // });
  },
  methods: {
    usableDiscountF(){
      this.usableDiscount = !this.usableDiscount;
      if (this.usableDiscount) {
        this.discounts = this.discounts.filter((discount) => discount.usableUntil > new Date().toISOString());
      }
      else{
        this.getDiscounts();
      }
    },
    getDiscounts() {
      axios.get(discountPath.getAll).then((res) => {
        this.discounts = res.data;
        // console.log("object :>> ", this.sessionList);
      });
    },
    saveButtonClicked() {
      if (this.sendData._id) {
        axios
          .patch(`${discountPath.new}/${this.sendData._id}`, this.sendData)
          .then((res) => {
            this.getDiscounts();
            this.$refs.discountModal.hide();
            // this.resetModal();
          });
      } else {
        axios.post(discountPath.new, this.sendData).then((res) => {
          this.getDiscounts();
          this.$refs.discountModal.hide();
          // this.resetModal();
        });
      }
    },
    checkUniqueCode() {
      const filterCodes = this.discounts.filter(
        (discount) => discount.code == this.sendData.code
      );
      if (filterCodes.length > 0 && filterCodes[0]._id != this.sendData._id) {
        this.$swal({
          title: this.$t("Error"),
          text: this.$t("Code must be unique!"),
          icon: "error",
          confirmButtonColor: "#de39a5",
          confirmButtonText: this.$t("Change the found discount"),
          cancelButtonColor: "#34bde6",
          cancelButtonText: this.$t("Change code"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.sendData = filterCodes[0];
          } else {
            this.sendData.code = "";
          }
        });
      }
    },
    resetModal() {
      this.sendData = {
        code: "",
        rate: 1,
        usageLimit: -1,
        type: "percent",
        usableByAll: true,
        usableBy: [],
        usableForAll: true,
        usableFor: [],
        usableUntil: null,
        _id: null,
      };
    },
    editDiscount(id) {
      this.sendData = this.discounts.find((item) => item._id == id);
      this.$refs.discountModal.show();
    },
    deleteDiscount(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Discount will be deleted!"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#ff4444",
        cancelButtonText: this.$t("No"),
        confirmButtonColor: "#00C853",
        confirmButtonText: this.$t("Yes"),
      }).then((result) => {
        if (result.value) {
          let data = this.discounts.find((item) => item._id == id);
          data.usableUntil = new Date().toISOString();
          axios.put(`${discountPath.new}/${id}`, data).then((res) => {
            this.getDiscounts();
            this.$refs.discountModal.hide();
            // this.resetModal();
          });
        } else {
          console.log("cancel");
        }
      });
      //user needs to confirm before deleting
    },

    // disconnectSession(id) {
    //   axios.delete(sessionPath.delete + "/" + id).then((res) => {
    //     this.getSessions();
    //     // console.log("object :>> ", this.sessionList);
    //   });
    // },
    // getSessions() {
    //   axios.get(sessionPath.get).then((res) => {
    //     this.sessionList = res.data;
    //     // console.log("object :>> ", this.sessionList);
    //   });
    // },
    // IPPARSER(ip) {
    //   // return ip.replace("::ffff:", "");
    //   return ip;
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// #sidebar-add-new-event {
//   height: auto !important;
// }
</style>
